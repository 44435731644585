import * as React from "react";
import { useEffect, useState, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import "@fontsource/creepster";
import "@fontsource/berkshire-swash";
import { graphql } from "gatsby";

import GlobalStyles from "../styles/globalStyles";
import Game from "../components/game";
import Scoreboard from "../components/scoreboard";
import breakpoints from "../styles/breakpoints";

export interface Game {
  name: string;
  isFinished: boolean;
  result: Clown[];
}

export interface Clown {
  name: string;
  points: number;
}

interface GameList {
  id: string;
  gameList: Game[];
}
interface QueryData {
  data: {
    allSanityGames: { nodes: GameList[] };
    allSanityClown: { nodes: Clown[] };
  };
}

const fadeIn = keyframes`
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}`;

const subtitleSpin = keyframes`
  0% {
      transform: rotate(0) scale(0.1);
      opacity: 1;
    }
    
    100% {
      transform: rotate(700deg) scale(5);
      opacity: 1;
  }
}`;

const witchPath = keyframes`
  0% {
    transform: translate3d(-100%, -100%, 0);
    opacity: 1;
  } 

  25% {
    animation-timing-function: ease-out;
    transform: translate3d(20vw, 50vh, 0);
  } 

  40% {
    animation-timing-function: ease-in;
    transform: translate3d(30vw, 20vh, 0);
  } 

  50% {
    transform: translate3d(100vw, 40vh, 0) rotateY(0);
  } 
  51% {
    transform: translate3d(100vw, 40vh, 0) rotateY(180deg);
  } 

  70% {
    animation-timing-function: ease-out;
    transform: translate3d(50vw, 20vh, 0)  rotateY(180deg);
  } 

  90% {
    animation-timing-function: ease-in;
    transform: translate3d(20vw, 80vh, 0)  rotateY(180deg);
  } 

  100% {
    transform: translate3d(-100%, -100%, 0)  rotateY(180deg);
    opacity: 1;
  } 
}`;

export const Witch = styled.div<{ playAnimation: boolean }>`
  background-image: url("/spooky-johan.png");
  background-size: auto 100%;
  width: 130px;
  height: 150px;
  z-index: 90;
  opacity: 0;

  position: absolute;
  top: 0;
  left: 0;

  @media ${breakpoints.tabletLandscape} {
    width: 265px;
    height: 300px;
  }

  ${(props) =>
    props.playAnimation &&
    css`
      animation-name: ${witchPath};
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-duration: 10s;
      animation-delay: -0.5s;
    `}
`;

export const centered = `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const StyledMain = styled.main`
  background-image: url("/moon-bg-mobile.jpg");
  background-size: cover;
  background-attachment: fixed;

  @media ${breakpoints.tabletLandscape} {
    background-image: url("/moon-bg.jpg");
  }
`;

const LandingSection = styled.section`
  background: rgba(0, 0, 0, 0.4);
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;

  > h1 {
    ${centered};
    color: #fff;
    font-family: "Berkshire Swash";
    font-size: 16vw;
    width: 100%;
    text-align: center;

    animation-fill-mode: forwards;
    animation: ${fadeIn};
    animation-duration: 4000ms;
  }

  button {
    position: absolute;
    color: #fff;
    font-family: "Berkshire Swash";
    cursor: pointer;
    bottom: 4vw;
    font-size: 15px;

    width: 150px;
    height: 100px;

    opacity: 0;
    animation: ${fadeIn};
    animation-duration: 200ms;
    animation-delay: 3250ms;
    animation-fill-mode: forwards;

    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    background: none;

    @media ${breakpoints.tabletLandscape} {
      right: 4vw;
      width: 200px;
      height: 150px;
      font-size: 18px;
    }

    > span {
      position: relative;
      z-index: 100;
      text-align: center;
      margin: 10px 0 0 10px;
    }

    > img {
      position: absolute;
      left: 0;
      width: 100%;

      &:last-of-type {
        z-index: 90;
        margin: 5px 0 0 10px;
      }
    }

    &:hover {
      > img {
        position: absolute;
        left: 0;
        width: 100%;

        &:first-of-type {
          z-index: 90;
          margin: 5px 0 0 10px;
        }

        &:last-of-type {
          z-index: 80;
          margin: 0;
        }
      }
    }
  }
`;

const Subtitle = styled.span`
  ${centered};
  font-family: "Creepster";
  font-size: 1.2vw;
  color: #f92929;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;

  transform-origin: center;
  opacity: 0;
  animation: ${subtitleSpin};
  animation-fill-mode: forwards;
  animation-duration: 1500ms;
  animation-delay: 1500ms;
`;

const GameSection = styled.section`
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 300px;
  position: relative;
  color: #fff;

  padding-bottom: 100vh;

  h2 {
    text-transform: uppercase;
    color: #fff;
  }

  > div {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 29%;

    line-height: 1.5;

    padding: 10px;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  ul {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;

    list-style: none;
  }

  > img {
    position: absolute;
    bottom: 0;
    left: 10vw;
    width: 300px;
  }
`;

const IndexPage = ({ data }: QueryData) => {
  const [initiated, setInitiated] = useState(false);
  const [finishedGames, setFinishedGames] = useState([]);
  const [upcomingGames, setUpcomingGames] = useState([]);
  const games = data?.allSanityGames?.nodes[0].gameList;
  const clowns = data?.allSanityClown?.nodes;

  games.map((game) => (game.name = game.name.split(" - Ågs 2")[0]));
  const audioRef = useRef();

  useEffect(() => {
    setFinishedGames(games.filter((game) => game.isFinished));
    setUpcomingGames(games.filter((game) => !game.isFinished));
  }, []);

  return (
    <>
      <GlobalStyles />
      <StyledMain>
        <LandingSection>
          <audio loop ref={audioRef}>
            <source src="/skratt.mp3" type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>

          <h1>Ågs Bruk</h1>
          <Subtitle>The Re-Awakening</Subtitle>
          <Witch playAnimation={initiated} />
          {!initiated && (
            <button
              onClick={(event) => {
                event.preventDefault();
                audioRef?.current?.play();
                event.target.style.display = "none";
                setInitiated(true);
              }}
            >
              <img src="/purple.png" />
              <img src="/green.png" />
              <span>
                Show me the
                <br />
                WITCH
              </span>
            </button>
          )}
        </LandingSection>

        <GameSection>
          <div>
            <h2>Kommande spel</h2>
            {upcomingGames.map((game, index) => {
              return <Game game={game} key={`upcoming-${index}`} />;
            })}
          </div>

          <div>
            <h2>Avslutade spel</h2>
            {finishedGames.map((game, index) => {
              return <Game game={game} key={`finished-${index}`} />;
            })}
          </div>

          <div>
            <h2>Poängställning</h2>
            <Scoreboard games={finishedGames} clowns={clowns} />
          </div>

          <img src="/tombstone.png" />
        </GameSection>
      </StyledMain>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query Aag2Query {
    allSanityGames(filter: { title: { eq: "Game List Aag 2" } }) {
      nodes {
        id
        gameList {
          name
          isFinished
          result {
            name
          }
        }
      }
    }
    allSanityClown {
      nodes {
        name
        points
      }
    }
  }
`;
